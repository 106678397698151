import { ShopItem, ShopItemEntityType } from '../events/shop';
import { RefundReason, RefundStatus } from '../refund';
import { RefundPolicy } from '../refund-policy';
import { Picture } from '../shared';
import { TicketSetting } from '../tickets/ticket-setting';
import { PromoCode } from './promocode';

export interface ProcessOrderDTO {
  paymentIntentId: string;
  cartId: number;
}

export interface TicketResponse {
  tickets: Ticket[];
  orderConfirmationIds: string[];
}

export enum TicketStatus {
  Valid = 'VALID',
  Cancelled = 'CANCELLED',
}

export interface CartItemDTO {
  id: number;
  title: string;
  slug: string;
  description?: string;
  maxPerOrder?: number;
  from?: Date;
  to?: Date;
  quantity: number;
  quantityLeft: number;
  fees: number;
  net: number;
  totalPrice: number;
  bundleSize: number;
  refundPolicy: RefundPolicy;
  maxPerUser?: number;
  eventId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  quantityInCart: number;
  waitingListEnabled?: boolean;
}

export interface CartShopItemDTO {
  id: number;
  entityId: number;
  entityType: ShopItemEntityType;
  title: string;
  net: number;
  price: number;
  description?: string;
  maxPerOrder?: number;
  maxPerUser?: number;
  isVisible: boolean;
  quantity: number;
  quantityLeft: number;
  fees: number;
  totalPrice: number;
  picture?: Picture;
  eventId: number;
  createdAt: Date;
  updatedAt: Date;
  refundPolicy: RefundPolicy;
  refundDaysLimit?: number;
  deletedAt?: Date;
  quantityInCart: number;
}

export interface CreateCartDTO {
  items: TicketItemDTO[];
  promoCodeId?: number;
}

export interface ShopItemDTO {
  shopItemId: number;
  quantity: number;
}

export interface TicketItemDTO {
  ticketSettingId: number;
  quantity: number;
}

export interface CartDTO {
  items?: TicketItemDTO[];
  shopItems?: ShopItemDTO[];
  promoCodeId?: number;
}

export interface CartItem {
  id: number;
  quantity: number;
  price: number;
  ticketSettingId: number;
  shopItemId?: number;
  shopItem: ShopItem;
  createdAt: Date;
  updatedAt: Date;
  ticketSetting: TicketSetting;
  discount: number;
  netAmount: number;
  processingFee: number;
  platformFee: number;
  snapshot?: ItemSnapshot;
  refundRequests?: RefundRequest[];
}

export interface ItemSnapshot {
  id: number;
  originalItemId: number;
  type: ItemType;
  refundPolicy: RefundPolicy;
  refundDaysLimit?: number;
  price: number;
  title?: string;
  description?: string;
  metadata: Record<string, string>;
  createdAt: Date;
}

export enum ItemType {
  TICKET = 'TICKET',
  SHOP_ITEM = 'SHOP_ITEM',
}

export enum CartStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  WaitListGranted = 'WAITING_LIST_GRANTED',
  PendingApproval = 'PENDING_APPROVAL',
}

export interface Cart {
  id: number;
  eventId: number;
  userId: number;
  orderId?: number;
  status: CartStatus;
  total: number;
  paymentIntentId: string;
  subtotal: number;
  items: CartItem[];
  shopItems?: ShopItemDTO[];
  promoCodeId?: number;
  promoCode?: PromoCode;
  createdAt: Date;
  updatedAt: Date;
  fees: number;
  discount: number;
}

export interface CartResponse extends Cart {
  client_secret?: string;
}

export interface Ticket {
  id: number;
  title?: string;
  slug: string;
  qrCodeId: string;
  validityDate: Date;
  ticketSettingId: number;
  orderId: number;
  ticketSetting: TicketSetting;
  order: Order;
  eventId: number;
  event: Event;
  scannedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  transferredTo?: TransferredTicket;
  user?: { firstname: string; pictures: Picture[]; email: string };
  userId: number;
  status: TicketStatus;
}

export interface RefundRequest {
  id: number;
  userId: number;
  orderId: number;
  description: string;
  status: RefundStatus;
  createdAt: Date;
  updatedAt: Date;
  processedAt?: Date;
  reason?: RefundReason;
  items?: CartItem[];
  order?: Order;
}

export interface Order {
  id: number;
  orderConfirmationId: string;
  amount: number;
  paymentMethodId?: string;
  paymentIntentId?: string;
  cartId: number;
  cart?: Cart;
  tickets?: Ticket[];
  userId: number;
  eventId: number;
  createdAt: Date;
  updatedAt: Date;
  refundRequest: RefundRequest;
}

export enum TransferStatus {
  PENDING = 'PENDING',
  CLAIMED = 'CLAIMED',
  EXPIRED = 'EXPIRED',
}

export interface TransferredTicket {
  id: number;
  ticketId: number;
  ticket?: Ticket;
  recipientEmail: string;
  status: TransferStatus;
  claimedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  user: {
    email: string;
    pictures: Picture[];
    firstname: string;
  };
  userId: number;
}

export interface TransferTicketDTO {
  ticketId: number;
  recipientEmail: string;
}

export interface DeleteTransferTicketDTO {
  ticketId: number;
  transferredId: number;
}
