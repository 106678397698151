import { Picture } from '../shared';

export enum EmailPreferenceEntityType {
  Organization = 'organization',
}

export interface EmailPreference {
  email: string;
  entityType: EmailPreferenceEntityType;
  entityId: number;
  createdAt: string;
  updatedAt: string;
  marketing: boolean;
}

export interface UpsertEmailPreferenceDTO {
  email: string;
  marketing: boolean;
}

export interface EmailEntity {
  id: number;
  title: string;
  logo: Picture;
}
