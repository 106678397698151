import { Order, Ticket } from '../payments';
import { Picture } from '../shared/picture';
import { TicketSetting } from '../tickets';
import { Timezone } from '../timezone';
// eslint-disable-next-line import/no-cycle
import { User, Interest } from '../users';
import { CurrencyCodes } from '../users/currency';
import { EventTag } from './event-tag';
// eslint-disable-next-line import/no-cycle
import { IOrganization } from './organization';
import { Venue } from './venue';

export enum EventStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
}

export enum EventType {
  PUBLIC = 'PUBLIC',
  EXCLUSIVE = 'EXCLUSIVE',
}

export enum EventParticipantStatus {
  GOING = 'GOING',
  CANCELLED = 'CANCELLED',
  NOT_REQUESTED = 'NOT_REQUESTED',
}

export interface LiveReview {
  id: number;
  score: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  eventId: number;
  userId: number;
  user: User;
  event: Event;
}

export interface IEventParticipant {
  firstname: string;
  id: number;
  pictures: Picture[];
  showAvatar: boolean;
}

export interface IAvatar {
  modelURL: string;
  avatarURL: string;
  avatarId: string;
  userId: string;
}

export interface IAttendee {
  id: number;
  firstname?: string;
  isVerified?: boolean;
  birthdate?: string;
  occupation?: string;
  description?: string;
  interests?: Interest[];
  pictures: Picture[];
  matchId?: number;
  currency?: CurrencyCodes;
  pastEvents: Pick<IEvent, 'id' | 'cover' | 'title'>[];
  requestSent: boolean;
  showAvatar?: boolean;
  chatChannelId?: string;
}

export interface IOrganizer {
  name?: string;
  url?: string;
  twitter?: string;
  summary?: string;
  facebook?: string;
  website_url?: string;
  pictureURL?: string;
}

export interface IMetadata {
  url?: string;
  eventbriteURL?: string;
}

export enum DisplayMode {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export interface EventConfiguration {
  waitingListEnabled?: boolean;
  isVisible: boolean;
  fontColor?: string;
  fontFamily?: string;
  animation?: string;
  displayMode: DisplayMode;
}
export interface IEvent {
  id: number;
  title: string;
  description: string | null;
  slug: string;
  date: Date;
  startDate: Date;
  endDate: Date;
  timezone?: Timezone;
  currency?: CurrencyCodes;
  instagram: string | null;
  pictures: Picture[];
  configuration: EventConfiguration;
  waitingListEnabled: true;
  tags: EventTag[];
  type: EventType;
  status: EventStatus;
  cartId: number;
  distance?: number;
  createdAt: Date;
  updatedAt: Date;
  isSellingFast?: boolean;
  isSoldOut?: boolean;
  isAlmostSoldOut?: boolean;
  venueId: number;
  userId: number;
  venue: Venue;
  metadata?: IMetadata;
  url?: string;
  cover: Picture;
  user: User;
  organizer?: IOrganizer;
  participants: IEventParticipant[];
  liveReviews: LiveReview[];
  ticketSettings: TicketSetting[];
  tickets?: Ticket[];
  connect: boolean;
  hasPendingRequest?: boolean;
  userOrders?: Pick<Order, 'id' | 'orderConfirmationId'>[];
  eventParticipantsCount: number;
  eventParticipantStatus?: EventParticipantStatus;
  orders?: Order[];
  organizationId?: number;
  organization?: IOrganization;
  flatFee?: number;
}
